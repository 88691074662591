import ReactEChart from 'echarts-for-react';



export function UnpaidARChart (chartData) {

    if (chartData.chartData) {
    const totalOutstanding = chartData.chartData.OUTSTANDING;
    const percentCurr = chartData.chartData.PERCENT_CURR === 0 ? 0 :  chartData.chartData.PERCENT_CURR;
    const percent31 = chartData.chartData.PERCENT_31_60;
    const percent61 = chartData.chartData.PERCENT_61_90;
    const percentOverdue = chartData.chartData.PERCENT_OVERDUE;

    const pieChartOptions = {
        tooltip: {
        trigger: 'item'
        },
        graphic: {
            elements: [{
              type: 'text',
              left: 'center',
              top: 'middle',
              z: 999,
              style: {
                text: chartData.centerLabel,
                textAlign: 'center',
                fontSize: 26,
              }
            }]
          },
        series: [
        {
            name: 'Unpaid AR',
            type: 'pie',
            radius: ['55%', '95%'],
            avoidLabelOverlap: true,
            startAngle: 180,
            label: {
            show: false,
            position: 'center'
            },
            emphasis: {
                label: {
                    position: 'inside',
                    show: true,
                    fontSize: 20,
                    fontWeight: 'bold',
          
                    formatter(param) {
                        // correct the percentage
                        return param.name + ' (' + param.percent + '%)';
                    },
                    itemStyle: {
                        z: 999,
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                }
            },
            data: [
            { value: percentCurr, name: 'Current'},
            { value: percent31, name: '31-60 Days', itemStyle: {}},
            { value: percent61, name: '61-90 Days', itemStyle: {}},
            { value: percentOverdue, name: 'Overdue', itemStyle: {}},
            ],
        }
        ]
    }
    return (<ReactEChart option={pieChartOptions} className='upaid-ar-pie-chart' style={{height: '200px', width: '275px'}}/>)
  }
}

export function RingGuageUtilization (props) {

}