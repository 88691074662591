import Dexie from "dexie";
import { localTables } from "./DexieTables";

// Tables to be generated with highest level of permission.  For instance if the highest level is
// client_manger then the MagnifyDB will only be populated with that level.

export const db = new Dexie("MagnifyDB");
db.version(1.9).stores(localTables);
db.open()
    .catch(Dexie.VersionError, (error) => {
        console.error("Error with upgrade of Dexie version. Rebuilding MagnifyDB");
        db.delete();
        window.location.reload(true);
    })
    .catch(Dexie.UpgradeError, (error) => {
        console.error("Error with upgrade of Dexie version. Rebuilding MagnifyDB");
        db.delete();
        window.location.reload(true);
    })
    .catch((err) => console.error(err.stack || err));

db.on("versionchange", (event) => {
    console.warn("Version Change Happened");
    db.delete();
    window.location.reload(true);
});

export function DataRefreshTimer(cb, data, startTime) {
    // Still working on this
    const interval = 1 * 1000;
    let targetTime = Date.now() + interval;
    if (Date.now() <= targetTime) {
        targetTime += interval;
        cb(targetTime);
        requestAnimationFrame(DataRefreshTimer(targetTime, cb));
    } else {
    }
    window.requestAnimationFrame(DataRefreshTimer(targetTime, cb));
}

export async function UpdateLessChangelog(dataRequests, localDB, headers) {
    try {
        fetch(dataRequests.endpoint, {
            method: "GET",
            mode: "cors",
            headers: headers,
        })
            .then((resp) => resp.json())
            .then(async (data) => {
                // console.log(dataRequests.endpoint);
                const dataLoad = { FOCUS: dataRequests.table, ...data[0] };
                localDB["STATIC"].put(dataLoad);
            });
    } catch (error) { }
}

export const GetRemoteVersions = async (remoteObject) => {
    const version_data = await fetch(remoteObject.changeTable, {
        method: "GET",
        mode: "cors",
        headers: remoteObject.tableHeaders,
    });
    return version_data.json();
};

export function DataRefresher(
    remoteDataRequests,
    localDB,
    headers,
    remoteChangeTable = null
) {
    const get_stored_version = async (checkTable) => {
        let resp = {};
        resp = await localDB.CHANGE_LOG.where("DATA_TABLE")
            .equals(checkTable.tableName)
            .first();
        // console.log(`The Local Hash Version For ${checkTable.tableName} is ${resp}`)
        try {
            checkTable["localHash"] = resp.HASH_VALUE;
        } catch {
            checkTable["localHash"] = null;
        }
        // checkTable['localHash'] = null
        return checkTable;
    };

    const update = new Promise((cb) => {
        const tableName = remoteDataRequests.table;
        let remoteHash = remoteDataRequests.remoteversion;
        // console.log(`Remote ${tableName} hash version: ${remoteHash}`);

        // console.log(`Fetching Stored Hash Version For ${tableName}`);
        get_stored_version({ tableName: tableName })
            .then(async (data) => {
                let localHash = null;
                try {
                    localHash = data.localHash;
                    // console.log(`${localHash} - ${remoteHash}`)
                } catch {
                    console.log("Stored Hash Value Not Available");
                    remoteHash = null;
                }
                if (data.localHash === true) {
                    console.log(`Refreshing ${data.tableName} table`);
                    return data;
                } else if (remoteHash === localHash) {
                    // console.log("Version Match");
                    console.log(
                        `${data.tableName} data current relative to intermediate database.`
                    );
                    // return Promise.reject(()=>{console.log("Versions Match")})
                    return false;
                } else {
                    console.log("Versions Do Not Match!");
                    // console.log(data.tableName, "Stored Version: ", localHash);
                    // console.log(data.tableName, "Remote Version: ", remoteHash);
                    console.log(
                        `${data.tableName} outdated syncing to intermediate database`
                    );
                    // return load_remote_data(remoteDataRequests.endpoint, data);
                    return data;
                }
            })
            .then(async (contextData) => {
                if (contextData === false) {
                    return "Version Up To Date";
                } else {
                    const response = await fetch(remoteDataRequests.endpoint, {
                        method: "GET",
                        mode: "cors",
                        headers: headers,
                    });
                    response
                        .json()
                        .then(async (respData) => {
                            // Delete old keys before inserting new data
                            await deleteOldKeys(localDB, contextData.tableName, respData);

                            let action = localDB[contextData.tableName].bulkPut(respData);
                            action.then((dexieResp) => {
                                let updateHash = localDB.CHANGE_LOG.put({
                                    DATA_TABLE: contextData.tableName,
                                    HASH_VALUE: remoteHash,
                                });
                                cb();
                                return updateHash;
                            })
                            .catch((err) => {
                                console.log(contextData.tableName);
                                console.error(err);
                            });
                            return respData;
                        })
                        .catch((err) => {
                            console.log(contextData.tableName);
                            console.error(err);
                        });
                }
            });
    });
    return update;
}

// New helper function to delete old keys
async function deleteOldKeys(localDB, tableName, newData) {
    try {
        const sourceKeys = newData.map(row => row.KEY);
        const localData = await localDB[tableName].toArray();
        const localKeys = localData.map(row => row.KEY);
        const keysToDelete = localKeys.filter(key => !sourceKeys.includes(key));

        if (keysToDelete.length > 0) {
            await localDB[tableName].bulkDelete(keysToDelete);
            console.log(`Old values removed from ${tableName}`);
        }
    } catch (err) {
        console.error(`Error removing old values from ${tableName}`);
        console.error(err);
    }
}
