export var localTables = {
    CHANGE_LOG: `
    DATA_TABLE,
    HASH_VALUE`,

    CONTACTS: `
    INDEX,
    NAME,
    FIRST_NAME,
    LAST_NAME,
    ADDRESS,
    CITY,
    STATE,
    POSTAL_CODE,
    COUNTRY,
    PHONE,
    EMAIL,
    CELL_NUMBER,
    ORIGINATING_OFFICE,
    STATUS,
    CONTACT_CODE,
    IS_CLIENT,
    IS_FAMILY,
    UPDATED_ON,
    CREATED_ON
    `,

    COUNTRIES: `
    INDEX,
    VALUE,
    NAME
    `,

    ENGAGEMENT: `
    INDEX,
    CONTINDEX,
    CLIENTCODE,
    CLIENTNAME,
    CLIENTSHORTCODE,
    CLIENTORGANISATION,
    CLIENTOFFICE,
    CLIENTPARTNER,
    CLIENTMANAGER,
    CLIENTSTATUS,
    CLIENTOWNERSHIP,
    CLIENTRATING,
    CLIENTCREATED,
    CLIENTUPDATED,
    CLIENTUPDATEDBY,
    CLIENT_TYPE`,

    ENTERED_CLIENTS: `
    KEY,
    SUBMITTER,
    SUBMIT_TIME,
    CLIENT_NAME,
    CLIENT_BOI,
    STATUS,
    BOI_CLEARED
    `,

    ENTITY: `
    INDEX,
    NAME`,

    INDUSTRIES: `
    INDEX,
    NAME`,

    JOBS: `
    INDEX,
    JOB_IDX,
    CONTINDEX,
    JOB_NAME,
    STAFF`,

    JOB_TEMPLATES: `
    INDEX,
    JOB_IDX,
    TMPL_IDX,
    TYPE,
    NAME,
    CODE,
    TOTAL`,

    NEW_CLIENTS: `
    requestNumber,
    status,
    requestingEnity,
    requestingUser,
    clientName,
    clientRelationship,
    clientScope,
    officeSelection,
    relationshipValue,
    partnerSelection,
    managerSelection,
    entitySelection,
    industrySelection,
    clientAddress,
    clientCity,
    clientLocation,
    clientState,
    clientPostalCode,
    clientCountry,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhoneNumber,
    contactAddress,
    contactCity,
    contactState,
    contactPostalCode,
    contactSameAsClient,
    FYE,
    estimatedFeesClientLevel,
    estHrsClientLevel,
    billingEmail,
    billingAddress,
    billingCity,
    billingState,
    billingPostalCode,
    billingSameAsClient,
    billingSameAsContact,
    invoiceMethod,
    ServiceSelection,
    services`,

    OFFICES: `
    INDEX,
    NAME,
    PHONE,
    FAX,
    ADDRESS`,

    OUTSTANDING_WIP: `
    id++,
    OUTSTANDING_WIP,
    CLIENT_PARTNER,
    CLIENT_MANAGER,
    CLIENT,
    CODE,
    OFFICE,
    CLIENT_PARTNER_IDX,
    CLIENT_MANAGER_IDX,
    CLIENT_PARTNER_DEPT,
    CLIENT_MANAGER_DEPT,
    PARTNER_HOMEROOM,
    MANAGER_HOMEROOM,
    AGING_PERIOD`,

    REASONS: `
    INDEX,
    NAME`,

    REQUESTS: `
    INDEX,
    REQUEST_INDEX,
    DATE,
    SUBMIT_USER,
    SUBMIT_EMPLOYEE,
    SUBMIT_HOMEROOM,
    SUBMIT_LEVEL,
    SUBMIT_DEPARTMENT,
    REQUEST_USER,
    REQUEST_EMPLOYEE,
    REQUEST_HOMEROOM,
    REQUEST_LEVEL,
    REQUEST_DEPARTMENT,
    JOB,
    JOB_NAME,
    CLIENT,
    CLIENT_NAME`,

    SERVICES: `
    INDEX,
    SERVCODE,
    SERVNAME,
    SERVORGANISATION,
    SERVPERSON`,

    SOURCES: `
    INDEX,
    NAME`,

    STAFF: `
    STAFFINDEX,
    EMPLOYEE,
    EMAIL,
    IS_MANAGER,
    CLIENT_RESPONSIBLE,
    OFFICE,
    DEPARTMENT,
    BVSA,
    BILLABLE,
    LEADER,
    CSC`,

    STATIC: `
    FOCUS,
    OUTSTANDING,
    PERCENT_CURR,
    PERCENT_31_60,
    PERCENT_61_90,
    PERCENT_OVERDUE`,

    UNPAID_AR: `
    id++,
    UNPAID_INVOICE,
    CLIENT_PARTNER,
    CLIENT_MANAGER,
    CLIENT,
    OFFICE,
    CLIENT_PARTNER_IDX,
    CLIENT_MANAGER_IDX,
    CLIENT_PARTNER_DEPT,
    CLIENT_MANAGER_DEPT,
    AGING_PERIOD`,
};
