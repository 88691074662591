import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'user',
  initialState: {
      authenticated: 'pending',
      bearer: "VxeRAx9hjVuTo6pEM7UdGVMrICjFmlE+iwz3J4Cx4He3oShbMElvowFm8HooaRZbxfgL+8nKe+MC/iQV369+LuA4NFSkGOjfNbsvf+DakutJztyLfYnGvD93tsMv+ZOBQn5Ql+7JKUk0B8uX+yP0Jl7ki9R5rzTZy0l9FbuKBGkFhgSpySrSAxOlGO2xkRQ1ylf/7QDH9icOKk51xhfdKlA3xOVU3S0BAsu9rO7Icwdx2BB5L+FtOVfxr3j+Lb/2q2yc8O94o4gn+LJXApufTQEi2tQzJ0QAJOcdfzSMEM54zRyr31zz8E/qQ8F2FYf3KJ5+3+PCUrvfd81TslRUzA=="
  },
  reducers: {
    setAuthentication: (state, auth) => {
        state.authenticated = auth.payload
    },
    setUser: (state, user) => {
        // const newState = {
        //     authenticated: true, 
        //     ...user.payload
        // }

          return user.payload
    },
    clearUser: () => {
      console.log('here')
      console.log(Object.keys(sessionStorage))
      for (let i = 0; i < sessionStorage.length; i++) {
        console.log(sessionStorage.key(i))
        sessionStorage.removeItem(sessionStorage.key(i))
      }
      // sessionStorage.removeItem('persist:root')
      //   return {
      //     authenticated: 'pending',
      //     bearer: "TkQ6kYJB3Prcgq8WBJjv2XJijJM9SNAONNYPMGVgOkHZGbCc1D+rxh8Pa7NMee/1q0zKqZy6WJAjqxuYBMwBxwo13gFiSDG0ZoLWanfB5nLbm/TOlO3QC5HYrrF5A1AQ7/o83zaCKuBA7nSY1UMJjAqHqK7a74YGb71V0PQJT6l4hcEklPJWpD9o0+4KNasdSK7wwM9gtePQWze5prR+Ff1VAM9WpaSlfi+6oYZwE/ZAApt/WJKDW2UWTcuCXNDiLqswAgwz4JSZhMd5KNCSJCPCfNJh7FucVZoD2Xv1FxxJ0h6oPrIH/2BXEoDN3RKLuAMOQhke5CFATxmrjHszxQ=="
      // }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAuthentication, setUser, clearUser } = loginSlice.actions

export default loginSlice.reducer