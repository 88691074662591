import { useSelector } from "react-redux";
import { useState, useEffect, Fragment } from "react";
import { SyncLoader } from "react-spinners";
import { RingGuage } from "../../../components/visualizations/charts/RingCharts";

import { Splitter, SplitterPanel } from "primereact/splitter";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";

import "primeicons/primeicons.css";
import "../../../style/mywip.css";

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function CyPyMetricGauge(props) {
  const className = `${props.name}-metric-guage`;
  const p1_color = props.colors ? props.colors[0] : null;
  const p2_color = props.colors ? props.colors[1] : null;

  return (
    <div className={className}>
      <RingGuage
        min_value={props.min_value}
        max_value={props.max_value}
        colors={{ primaryColor: p1_color, secondaryColor: p2_color }}
        py={{
          data: props.py,
          color: props.colors,
        }}
        cy={{
          data: props.cy,
          color: props.colors,
        }}
        angle={props.angle}
        label={props.label}
      />
    </div>
  );
}

function UtilizationMetricCard(props) {
  const InternalCard = (cardData) => {
    const footer = () => {
      const makeComparison = (value) => {
        const calcValue = cardData.value - value;
        const dispValue = cardData.formater(calcValue.toFixed(2));
        // console.log(calcValue);
        const color =
          Math.sign(calcValue) === 1
            ? "text-green-500 pi pi-arrow-up"
            : "text-red-500 pi pi-arrow-down";
        return <span className={`${color}`}> {dispValue}</span>;
      };
      let foot = cardData.comparator ? (
        makeComparison(cardData.comparator)
      ) : (
        <span className="pi pi-ellipsis-h"></span>
      );
      return foot;
    };

    return (
      <Card
        className="col-4 m-2 text-2xl py-0 font-bold mywip-metric-card"
        title={cardData.label}
        pt={{ title: { className: "text-sm" } }}
        footer={footer}
      >
        {cardData.formater(cardData.value)}
      </Card>
    );
  };
  return (
    // <div className={`flex w-5 ml-3 flex-wrap justify-content-${props.location ? props.location : "center"}`}>
    <div
      className={`flex w-5 xlg:px-6 flex-wrap justify-content-evenly ${props.classTags}`}
    >
      <h3 className="text-lg w-full ml-3 text-center">{props.label}</h3>
      <InternalCard
        label="Total Hours"
        comparator={
          props.comparatorData
            ? props.comparatorData.BILLABLEHOURS +
              props.comparatorData.NONBILLABLEHOURS
            : null
        }
        value={props.data.BILLABLEHOURS + props.data.NONBILLABLEHOURS}
        formater={(value) => new Intl.NumberFormat().format(value)}
      />
      <InternalCard
        label="Utilization"
        comparator={
          props.comparatorData ? props.comparatorData.UTILIZATION : null
        }
        value={props.data.UTILIZATION}
        formater={(value) => value + "%"}
      />
      <InternalCard
        label="Billable Hours"
        value={props.data.BILLABLEHOURS}
        comparator={
          props.comparatorData ? props.comparatorData.BILLABLEHOURS : null
        }
        formater={(value) => `${new Intl.NumberFormat().format(value)} Hrs`}
      />
      <InternalCard
        label="Non-Billable Hours"
        value={props.data.NONBILLABLEHOURS}
        comparator={
          props.comparatorData ? props.comparatorData.NONBILLABLEHOURS : null
        }
        formater={(value) => `${new Intl.NumberFormat().format(value)} Hrs`}
      />
    </div>
  );
}

function RealizationMetricCard(props) {
  const InternalCard = (cardData) => {
    const footer = () => {
      const makeComparison = (value) => {
        const calcValue = cardData.value - value;
        const dispValue = cardData.formater(calcValue.toFixed(2));
        // console.log(calcValue);
        const color =
          Math.sign(calcValue) === 1
            ? "text-green-500 pi pi-arrow-up"
            : "text-red-500 pi pi-arrow-down";
        return <span className={`${color}`}> {dispValue}</span>;
      };
      let foot = cardData.comparator ? (
        makeComparison(cardData.comparator)
      ) : (
        <span className="pi pi-ellipsis-h"></span>
      );
      return foot;
    };
    const cardDisp = cardData.formater(cardData.value);
    return (
      <Card
        className="mywip-metric-card col-4 m-2 pt-0 text-2xl font-bold"
        title={cardData.label}
        pt={{ title: { className: "text-sm" } }}
        footer={footer}
      >
        {cardData.formater(cardData.value)}
      </Card>
    );
  };
  // console.log(props)
  return (
    // <div className={`flex w-5 ml-3 flex-wrap justify-content-${props.location ? props.location : "center"}`}>
    <div
      className={`flex w-5 pl-3 pr-3 flex-wrap justify-content-around ${props.classTags}`}
    >
      <h3 className="text-lg w-full ml-3 text-center">{props.label}</h3>
      <InternalCard
        label="WIP Amount"
        value={props.data.WIPAMOUNT}
        comparator={
          props.comparatorData ? props.comparatorData.WIPAMOUNT : null
        }
        formater={(value) => USDollar.format(value)}
      />
      <InternalCard
        label="WIP Billed"
        value={props.data.WIPBILLED}
        comparator={
          props.comparatorData ? props.comparatorData.WIPBILLED : null
        }
        formater={(value) => USDollar.format(value)}
      />
      <InternalCard
        label="Effective Rate"
        value={props.data.EFF_RATE}
        comparator={props.comparatorData ? props.comparatorData.EFF_RATE : null}
        formater={(value) => USDollar.format(value)}
      />
      <InternalCard
        label="Realization"
        value={props.data.REALIZATION}
        comparator={
          props.comparatorData ? props.comparatorData.REALIZATION : null
        }
        formater={(value) => value + "%"}
      />
    </div>
  );
}

export function MyWIP() {
//   document.title = `Magnify | My WIP`;

  const [pyMetrics, setPyMetrics] = useState(null);
  const [cyMetrics, setCyMetrics] = useState(null);
  const user = useSelector((state) => state.user.STAFFINDEX);

  const authToken = useSelector(state => state.user.bearer);
  const apiRoot = useSelector(state => state.globals.apiRoot)
  // const authToken = "yd2cz9M2lShJNs6C65JWPdmXGT+oAaSVedq5R6B7lOrXxxmCKd6uy2TR0xh4ekKsM2kJfb2bxU85r/0jdPSjud9k2YNf8r3tl/GlzDtYPYC3q2lELJJxswGLeHlJR0cvjPpxSQg6DYUzrRVVrGeSsfecE/IH2X9nHrBtTtbemnuKLIqhBZUOT22znbar/7DKcke3tbEtKNFvBxzYubhmFNsJynFkRJyD1wUoNYegCJcsCzoAv7NivGLoCezILHqLzeRd05EVa5ardFllSMTNdRw/9oH94GXGahqVH51o3Se8rkItfeViBXAKdDEZrKSTAnJwUQVN92X3F8hCCfBjFg=="
  const level = useSelector((state) => state.user.LEVEL);

  const pyEndPoint = `${apiRoot}/api/user/${user}/wip/pymetrics`;
  const cyEndPoint = `${apiRoot}/api/user/${user}/wip/cymetrics`;

  const getMetrics = async (query) => {
    const resp = await fetch(query, {
      method: "GET",
      mode: "cors",
      headers: { authorization: authToken },
    });
    return resp.json();
  };
  let pyLoaded = false;
  let cyLoaded = false;

  useEffect(() => {
    getMetrics(pyEndPoint).then((data) => {
      setPyMetrics(data[0]);
    });
    getMetrics(cyEndPoint).then((data) => {
      setCyMetrics(data[0]);
    });
  }, [setCyMetrics, setPyMetrics, cyEndPoint, pyEndPoint]);

  const angle = 270;
  const primaryColor = "#145DA0";
  const secondaryColor = "#2E8BC0";

  if (pyMetrics && cyMetrics) {
    return (
      <div className="content-panel grid justify-content-center">
        <div className="gauge-panel col-8 flex justify-content-around">
          <CyPyMetricGauge
            py={pyMetrics.UTILIZATION}
            cy={cyMetrics.UTILIZATION}
            // colors={[primaryColor, secondaryColor]}
            label="Utilization"
            name="utilization"
            angle={angle}
          />
          <CyPyMetricGauge
            py={pyMetrics.REALIZATION}
            cy={cyMetrics.REALIZATION}
            max_value="150"
            // colors={[primaryColor, secondaryColor]}
            label="Realization"
            name="realization"
            angle={angle}
          />
        </div>
        <div className="data-panel w-12 flex flex-wrap justify-content-center">
          {/* <Splitter className="w-full"> */}
          {/* <SplitterPanel className="flex align-items-center justify-content-center "> */}
          <UtilizationMetricCard
            label="Prior Year Hours and Utilization"
            data={pyMetrics}
            location="end"
            classTags="border-right"
          />
          <UtilizationMetricCard
            label="Current Year Hours and Utilization"
            data={cyMetrics}
            location="start"
            comparatorData={pyMetrics}
          />
          <RealizationMetricCard
            label="Prior Year Effective Rate and Realization"
            data={pyMetrics}
            location="end"
            classTags="border-right"
          />
          <RealizationMetricCard
            label="Current Year Effective Rate and Realization"
            data={cyMetrics}
            location="start"
            comparatorData={pyMetrics}
          />
          {/* </SplitterPanel> */}
          {/* <SplitterPanel className="flex align-items-center justify-content-center">
                            Panel 2
                        </SplitterPanel> */}
          {/* </Splitter> */}
        </div>
      </div>
    );
  } else {
    return (
      <div className="utilization-gauge flex justify-content-center">
        <h1>
          Syncing Data
          <SyncLoader
            color={primaryColor}
            cssOverride={{ textAlign: "center" }}
          />
        </h1>
      </div>
    );
  }
}
